import React from "react";
import { Footer } from "./Footer";
import { Hero } from "./Hero";
import { Intro } from "./Intro";
import { StickyHeader } from "./StickyHeader";
import { SubscriptionInfo } from "./SubscriptionInfo";
import { TextBlock } from "./TextBlock";
import { SEO } from "../../components/seo";
import * as Cookie from "js-cookie";
import qs from "query-string";

const MARKED_IOS_USER = "marked-ios-user";

function isMarked() {
  try {
    return localStorage.getItem(MARKED_IOS_USER);
  } catch {
    return "true";
  }
}

function mark() {
  try {
    return localStorage.setItem(MARKED_IOS_USER, "true");
  } catch {}
}

function waitForFacebookPixel() {
  const _fbc = Cookie.get("_fbc");
  const _fbp = Cookie.get("_fbp");
  if (!_fbc || !_fbp) {
    setTimeout(waitForFacebookPixel, 1000);
    return;
  }

  mark();

  fetch("https://prod.mysaffronapp.com/mark-ios", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ _fbc, _fbp }),
  });
}

export const LandingPage: React.FC = () => {
  React.useEffect(() => {
    if (window.location.search) {
      const parsed = qs.parse(window.location.search);
      if (parsed && Object.keys(parsed).length) {
        Cookie.set("qs", parsed);
        const iOS =
          !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        // person came from facebook ad and they are on iOS and we haven't done this before
        if (iOS && !isMarked()) {
          if (parsed.fbclid) {
            waitForFacebookPixel();
          } else {
            fetch("https://prod.mysaffronapp.com/mark-ref-ios", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                search: parsed,
                ref: parsed.ref || document.referrer,
              }),
            });
          }
        }
      }
    }
    if (document.referrer) {
      Cookie.set("ref", document.referrer);
    }
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <SEO />
      <StickyHeader />
      <Hero />
      <TextBlock />
      <Intro />
      {/* <Features /> */}
      <SubscriptionInfo />
      <Footer />
    </div>
  );
};

export default LandingPage;
