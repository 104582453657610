import React from "react";
import { Wrapper } from "../components";
import { GifBlock } from "../GifBlock";
import addRecipesGif from "./add-recipes.mp4";
import createCookbookGif from "./organize-recipes.mp4";
import planMenusGif from "./menu-planner.mp4";
import searchRecipesGif from "./search-recipes.mp4";

export const Intro = () => (
  <div style={{ marginTop: 60, marginBottom: 67 }}>
    <Wrapper>
      <GifBlock
        headerText="Add Recipes"
        descriptionText="Import from websites or enter them using our side-by-side recipe form."
        src={addRecipesGif}
        alt="importing a recipe into Saffron from a blog"
        fix
      />
      <GifBlock
        headerText="Organize Recipes"
        descriptionText="Arrange into cookbooks with sections."
        src={createCookbookGif}
        alt="browsing a Saffron cookbook flipping through recipes"
      />
      <GifBlock
        headerText="Find Recipes"
        descriptionText="Search your collection by title, ingredient, and/or author."
        src={searchRecipesGif}
        alt="search for a recipe then click to see a preview of each"
      />
      <GifBlock
        headerText="Plan Meals"
        descriptionText="Schedule what you’re cooking and when you’re cooking it."
        src={planMenusGif}
        alt="drag and drop recipes onto a calendar"
      />
    </Wrapper>
  </div>
);
