import React from "react";
import { Wrapper } from "../components";
import { theme } from "../../../ui/theme";
import { SubCard } from "./SubCard";
import styled from "styled-components";
import { CarrotSvg } from "./CarrotSvg";
import { VeggieSvg } from "./VeggieSvg";
import { VeggieBoardSvg } from "./VeggieBoardSvg";
import { whiteSvgBackground } from "../Hero/whiteSvgBackground";
import { PrettyLink } from "../../../ui/PrettyLink";
import { isPhone } from "../../../utils/isPhone";
import { changeUrl } from "../../../utils/changeUrl";

const CardContainer = styled.div({
  display: "grid",
  gridGap: 20,
  gridTemplateColumns: "repeat(3, 1fr)",
  marginTop: 40,
  "@media only screen and (max-width: 900px)": {
    alignItems: "center",
    gridTemplateColumns: "repeat(1, 1fr)",
  },
});

export const SubscriptionInfo = () => {
  return (
    <div
      style={{
        backgroundColor: "#EFF7F1",
        backgroundImage: whiteSvgBackground,
        paddingTop: 70,
        paddingBottom: 40,
      }}
    >
      <Wrapper>
        <h1
          style={{
            fontFamily: theme.fonts.secondary,
            fontWeight: 900,
            fontSize: "2.625rem",
            lineHeight: "3.125rem",
            color: "#444444",
            textAlign: "center",
          }}
        >
          The plan for you
        </h1>
        <CardContainer style={{ paddingBottom: 60 }}>
          <SubCard
            title="Budding Cook"
            subhead={
              <div
                style={{
                  fontFamily: theme.fonts.secondary,
                  fontSize: "1.5rem",
                  lineHeight: "1.5rem",
                  textAlign: "center",
                  color: "#999999",
                }}
              >
                free
              </div>
            }
            svg={<CarrotSvg />}
            numRecipes="up to 25 recipes"
            description="You can access your recipes anytime, anywhere, and on any number of devices."
            buttonText="Start using"
            onClick={() => {
              changeUrl("/register");
            }}
          />
          <SubCard
            title="Home Cook"
            subhead={
              <>
                <div
                  style={{
                    fontFamily: theme.fonts.secondary,
                    fontSize: "1.5rem",
                    lineHeight: "1.5rem",
                    textAlign: "center",
                    color: "#999999",
                  }}
                >
                  <span style={{ fontSize: "2.0rem" }}>$5</span>
                  /month
                </div>
                <div
                  style={{
                    marginTop: 6,
                    textAlign: "center",
                    color: "#999999",
                  }}
                >
                  with an annual subscription
                </div>
              </>
            }
            svg={<VeggieSvg />}
            svgHeight={120}
            numRecipes="up to 1000 recipes"
            description="The same Saffron experience, but with more room for your extensive recipe collection."
            buttonText="Upgrade"
            onClick={() => {
              if (isPhone()) {
                changeUrl("/login/na?next=/account");
              } else {
                changeUrl("/account");
              }
            }}
          />
          <SubCard
            title="Food Blogger"
            subhead={
              <div
                style={{
                  height: 27,
                }}
              />
            }
            svg={<VeggieBoardSvg />}
            numRecipes="&nbsp;"
            description="Get the tools and customization you need to create digital cookbooks for your followers."
            buttonText="Contact us"
            url="mailto:ben@mysaffronapp.com"
          />
        </CardContainer>
        <div style={{ textAlign: "center" }}>
          Want to move your recipes over from another application?{" "}
          <PrettyLink href="/docs/bulk-import">
            Checkout how to bulk import.
          </PrettyLink>
        </div>
      </Wrapper>
    </div>
  );
};
