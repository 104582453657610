import React from "react";

export const VeggieSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="100px" height="100px" viewBox="0 0 102.89 87.26" {...props}>
    <path
      d="M83.78 16.2a8.69 8.69 0 000-.89 8.23 8.23 0 00-8.18-8.23 8.42 8.42 0 00-1.52.15 9.62 9.62 0 00-18 0 7.1 7.1 0 00-1.31-.13A6.88 6.88 0 0047.9 14v.45a6.61 6.61 0 106.8 10.43A6.4 6.4 0 0066.33 26a6.4 6.4 0 0010.54.42 6.81 6.81 0 106.91-10.22z"
      fill="none"
      stroke="#546140"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      d="M56.23 16s4.15-4.64 8.55-1.41c0 0 6.23-3.44 8.43 2.06m-22.72 10.7s8.44 8.23 8.93 17.85a1 1 0 001 1h9a1 1 0 00.92-.59c.93-2 3.4-13.57 12.31-15.86"
      fill="none"
      stroke="#546140"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      fill="none"
      stroke="#546140"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M58.43 28.8l3.24 4.53 1.43-4.53m6.23-.26l-1.55 5.8 7.23-6.23"
    />
    <path
      d="M98.65 59l2.74-4.41a1.75 1.75 0 10-3-1.85l-2.93 4.72c-9.16-3.22-10.77 6.62-10.77 6.62l6.11-1.77.71 4.69L96 64.54 99.28 71s6.28-7.39-.63-12z"
      fill="none"
      stroke="#80975d"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      d="M96 64.54L91.51 67l-.68-4.73L85 64a23 23 0 00-2 2.5c-8.44 12.4-16 13.94-22.38 13s5.46 12.12 21.35 4.17c9.36-4.67 14.87-9.26 16.97-13.32z"
      fill="none"
      stroke="#80975d"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      d="M23.28 43.05c13.43-2.58 23 2.6 23 13.29S36.19 75.72 23.67 75.72 1 67 1 56.34s8.84-15.87 22.28-13.29z"
      fill="none"
      stroke="#b56545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      d="M31.74 42.52S31.67 40.69 37 39c0 0-4.83-2-8.5 0l-1.83-4.66-3.67 4.1-3.67-4.08L17.5 39C13.83 37 9 39 9 39c5.33 1.67 5.26 3.5 5.26 3.5"
      fill="none"
      stroke="#b56545"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
