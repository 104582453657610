import React from "react";
import { theme } from "../../../ui/theme";
import { GiantButton } from "./components";

interface Props {
  title: string;
  subhead: React.ReactElement | null;
  svg: JSX.Element;
  numRecipes: string;
  description: string;
  buttonText: string;
  url?: string;
  onClick?: () => void;
  svgHeight?: number;
}

export const SubCard: React.FC<Props> = ({
  title,
  subhead,
  svg,
  numRecipes,
  description,
  buttonText,
  onClick,
  url,
  svgHeight = 150,
}) => {
  const button = (
    <GiantButton
      style={{
        padding: "10px 0px",
        marginTop: 50,
        fontSize: "1.125rem",
      }}
      onClick={onClick}
    >
      {buttonText}
    </GiantButton>
  );
  return (
    <div
      style={{
        margin: "auto",
        width: "100%",
        maxWidth: 300,
        backgroundColor: "#Fff",
        border: "1px solid #DADADA",
        padding: "5px 20px",
        paddingBottom: 20,
      }}
    >
      <h3
        style={{
          marginTop: 10,
          fontFamily: theme.fonts.secondary,
          fontWeight: 300,
          fontSize: "2.25rem",
          lineHeight: "3.125rem",
          textAlign: "center",
          color: "#444",
          marginBottom: 6,
        }}
      >
        {title}
      </h3>
      {subhead}
      <div
        style={{
          height: svgHeight,
          display: "flex",
          paddingBottom: 10,
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        {svg}
      </div>
      <div
        style={{
          fontFamily: theme.fonts.secondary,
          fontSize: "0.875rem",
          lineHeight: "1.5rem",
          textAlign: "center",
          color: "#666666",
        }}
      >
        {numRecipes}
      </div>
      <div
        style={{
          marginTop: 30,
          fontFamily: theme.fonts.secondary,
          fontSize: "1.0rem",
          lineHeight: "1.5rem",
          textAlign: "center",
          color: "#6F6E6C",
        }}
      >
        {description}
      </div>
      {url ? <a href={url}>{button}</a> : button}
    </div>
  );
};
