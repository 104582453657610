import React from "react";

export const CarrotSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="70px" height="70px" viewBox="0 0 47.3 47.89" {...props}>
    <path
      d="M34.63 30.57C29.39 35.81 6.71 50.74 1.93 46s10.15-27.5 15.39-32.74 13.36-5.61 18.14-.83 4.41 12.9-.83 18.14z"
      fill="none"
      stroke="#bf8c4c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
    <path
      d="M32.47 10.28l4.2-6.74A1.15 1.15 0 0036.31 2l-1.25-.77a1.15 1.15 0 00-1.59.36L28.7 9.18a11.85 11.85 0 013.77 1.1zm3.05 2.21c.36.35.41.47 1.18 1.33l6.16-6.16a1.15 1.15 0 000-1.63L41.23 4.4a1.15 1.15 0 00-1.63 0l-6.29 6.29a16 16 0 012.21 1.8zm3.05 5.83l7.18-4.49a1.15 1.15 0 00.37-1.59L45.31 11a1.14 1.14 0 00-1.59-.37l-6.54 4.08a11.79 11.79 0 011.39 3.61zm-20.19-5.76l4.9 4.89m8.16 14.69l-3.27-3.26m-16.31-8.16l4.89 4.89m0 16.32l-4.08-4.08"
      fill="none"
      stroke="#bf8c4c"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);
