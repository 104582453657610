import MobileDetect from "mobile-detect";

export const isPhone = () => {
  if (typeof window === "undefined") {
    return false;
  }

  const md = new MobileDetect(window.navigator.userAgent);

  return md.phone();
};
