import React from "react";

export const VeggieBoardSvg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="115px" height="115px" viewBox="0 0 138 138" {...props}>
    <defs>
      <clipPath id="prefix__a">
        <rect x={2.5} y={1.5} width={135} height={135} rx={5.8} fill="none" />
      </clipPath>
    </defs>
    <rect x={2.5} y={1.5} width={135} height={135} rx={5.8} fill="#f3f2f0" />
    <g
      clipPath="url(#prefix__a)"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    >
      <path
        d="M51.86 54.73a6.33 6.33 0 01.51 1.71c.76 5.11 8.42 8.13 13.47 9.71a45.19 45.19 0 014.43 1h0v.18a11.74 11.74 0 017.08 11c-.2 6.8-6.25 12.45-13.69 12.54a13.12 13.12 0 01-10.87-5.21h0S38 70.55 38.26 59.86a14.59 14.59 0 01.09-1.66"
        stroke="#656c7c"
      />
      <path
        d="M42.06 49l-1-4.9a2.31 2.31 0 114.49-.91l1 5c4.71.12 6.64 2.88 7.31 7.27l-6.32-2.95-2.44 5.41-4-4.11-4.78 5.89c.08-5.7 2.54-9.15 5.74-10.7z"
        stroke="#656c7c"
      />
      <path
        d="M28.17 114.46h0a8.09 8.09 0 01-10.52-3.6L7.84 92.15A8.09 8.09 0 0111.64 81l2.14-1a8.08 8.08 0 0110.88 4.42l7.68 19.68a8.08 8.08 0 01-4.17 10.36z"
        stroke="#972409"
      />
      <path
        d="M11.53 80.79l2.14-1a7.62 7.62 0 011.87-.45 4.9 4.9 0 01.74-4.24 2.83 2.83 0 00-.49-4 2.88 2.88 0 00-4 .51 10.72 10.72 0 00-1.55 10 8 8 0 011.29-.82zM25 78.2l-2 .68a7.23 7.23 0 00-2.31 1.28 8 8 0 014 4.32l7.68 19.68a8 8 0 01-.47 6.84 7.33 7.33 0 002.8-.36h0a7.33 7.33 0 004.75-8.89L34.4 83.23A7.34 7.34 0 0025 78.2zm-7.35 32.66L7.84 92.15a8.07 8.07 0 01-.65-5.85 7.3 7.3 0 00-2.48.91l-1.83 1.08A7.34 7.34 0 00.5 98.68l10.65 15.95a7.35 7.35 0 0011.94.37 8.1 8.1 0 01-5.44-4.14z"
        stroke="#972409"
      />
      <path
        stroke="#977535"
        d="M94.63 127.41l5.02.56m.54 4.88l-.63-4.96-2.4 2.41m17.55-10.23l8.08-8.08-5.86-5.86L109 114a16.9 16.9 0 00-8.61 4.1 6.87 6.87 0 00-.1 9.78 7.16 7.16 0 0010 .14c1.71-1.57 3.23-5.3 4.42-7.95z"
      />
      <path
        d="M122.38 111.93l-5.69-5.69 21.09-6.74a.92.92 0 011.14.58l1.82 5.4a.91.91 0 01-.6 1.16z"
        stroke="#977535"
      />
      <path
        d="M125.18 103.53l4-12.61a.9.9 0 00-.57-1.13L123.22 88a.91.91 0 00-1.16.6l-5.25 17.64zm-2.8 8.4l.08.08.04-.12-.12.04z"
        stroke="#977535"
      />
      <path
        d="M133.9 100.71l-8.72 2.82 3.18-9.63 4.4-4.4a.92.92 0 011.29 0l4.89 4.89a.91.91 0 010 1.28z"
        stroke="#977535"
      />
      <path
        d="M94.34 37.83c.62 2.13 4.44 9 2.18 12.31C98.78 46.78 95 40 94.34 37.83c-.92-3.11-5.1-12 3-18.18s18.6 1.16 18.6 1.16l.49.33s10.76 7 8.09 16.79-12.57 9.36-15.79 9.69c-2.21.22-9.94-.79-12.2 2.57"
        stroke="#531410"
      />
      <path
        d="M114.41 20l2.09-11-3.88-.74-1.92 10.09a25.45 25.45 0 013.71 1.65z"
        stroke="#531410"
      />
      <path
        d="M115.9 20.81l.49.33s.81.65 1.56 1.33l6.83-10.15-4.15-2.79-6.81 10.13c.89.46 2.08 1.15 2.08 1.15zm5.1 4.64l8.72-5.65-2.15-3.32-9.44 6.11a24 24 0 012.87 2.86zm-25.94-2.99l4.15 2.79m7.42 15.84l8.3 5.58m7.51-5.79l-4.15-2.79m-15.45-20.04l3.32 2.24"
        stroke="#531410"
      />
      <path
        stroke="#4b6c12"
        d="M2.42 10.57l2.01 4.92m-.64-.22l4.58-2.7m-6.7 10.04c2.17-1.69 5.55-1 7.22-.47a5.82 5.82 0 00.69-1.39C10.52 18 8.27 10 7.44 7.25a.69.69 0 00-1.06-.35c-2.29 1.66-8.92 6.64-9.87 9.45a6.2 6.2 0 00-.3 1.52c1.64.6 4.79 2.13 5.46 4.74z"
      />
      <path
        d="M1.67 22.61a6.35 6.35 0 014.48-1 27.22 27.22 0 00-1.72-6.1.47.47 0 00-.64-.22 27.29 27.29 0 00-5.06 3.82 6.28 6.28 0 012.94 3.5z"
        stroke="#4b6c12"
      />
      <path
        d="M9.88 22.49s-5.25-2.21-8.22.11c-1-3.65-6.39-5-6.39-5S-7 22.07-1.82 25.4l-6.74 20a1 1 0 00.63 1.27l2.84.95A1 1 0 00-3.82 47l6.74-20c6.16.49 6.96-4.51 6.96-4.51zm6.76-7.13l2.01 4.93m-.65-.22l4.58-2.7M15.89 27.4c2.17-1.69 5.54-1 7.22-.46a6.64 6.64 0 00.68-1.39c.95-2.81-1.31-10.79-2.13-13.5a.71.71 0 00-1.07-.36c-2.29 1.66-8.92 6.64-9.87 9.45a6.2 6.2 0 00-.3 1.52c1.65.61 4.77 2.08 5.47 4.74z"
        stroke="#4b6c12"
      />
      <path
        d="M15.89 27.4a6.28 6.28 0 014.47-1 27.25 27.25 0 00-1.71-6.1.47.47 0 00-.65-.22 27.2 27.2 0 00-5.06 3.81 6.3 6.3 0 012.95 3.51z"
        stroke="#4b6c12"
      />
      <path
        d="M24.09 27.28s-5.24-2.21-8.22.12c-1-3.65-6.39-5-6.39-5s-2.3 4.52 2.91 7.84l-6.73 20a1 1 0 00.62 1.27l2.85 1a1 1 0 001.26-.63l6.74-20c6.16.4 6.96-4.6 6.96-4.6zm6.76-7.12l2.01 4.92m-.65-.22l4.59-2.7M30.1 32.2c2.17-1.7 5.54-1 7.22-.47a6 6 0 00.68-1.39c1-2.81-1.31-10.79-2.13-13.5a.7.7 0 00-1.06-.36c-2.3 1.66-8.93 6.65-9.87 9.46a5.78 5.78 0 00-.3 1.51c1.64.61 4.76 2.09 5.46 4.75z"
        stroke="#4b6c12"
      />
      <path
        d="M30.1 32.2a6.3 6.3 0 014.48-1 27.22 27.22 0 00-1.72-6.1.47.47 0 00-.65-.22 27.18 27.18 0 00-5.06 3.82 6.3 6.3 0 012.95 3.5z"
        stroke="#4b6c12"
      />
      <path
        d="M38.31 32.08s-5.25-2.21-8.23.11c-1-3.65-6.39-5-6.39-5s-2.3 4.47 2.91 7.81l-6.73 20a1 1 0 00.63 1.26l2.84 1a1 1 0 001.27-.62l6.73-20c6.17.44 6.97-4.56 6.97-4.56z"
        stroke="#4b6c12"
      />
      <path
        d="M83.68 94s-.05 0 0 0c5.86 5.86 19.11 2 29.66-8.56s14.44-23.77 8.59-29.63c0 0 0 0 0 0l-.12-.12S113.1 60 110.86 67.78A30.21 30.21 0 0095.7 82.94c-7.76 2.24-12.14 10.92-12.14 10.92"
        stroke="#a4b886"
      />
      <path
        d="M121.77 55.64s-9.62-1.57-24.7 13.51-13.51 24.71-13.51 24.71m5.51-7.38a6.63 6.63 0 018.59-7.17m10.42-10.42a6.64 6.64 0 017.4-8.55M98.29 78.36a6.63 6.63 0 019.09-8.71"
        stroke="#a4b886"
      />
      <path
        d="M88 120.48c0 11-10 4-22.25 4s-22.24 7-22.24-4 10-19 22.24-19 22.25 7.97 22.25 19z"
        stroke="#c5bfa9"
      />
      <path
        d="M72.65 125.59S81.51 140 74.24 140h-17c-7.27 0 1.59-14.39 1.59-14.39"
        stroke="#c5bfa9"
      />
      <path
        d="M81.19 48.57l-.21 1a4.84 4.84 0 01-7 3.31l-.91-.46a23.37 23.37 0 01-10.38-10.8l-6-12.72a6.72 6.72 0 013.24-8.9l4.55-2.14A6.72 6.72 0 0173.42 21l6 12.84a23.37 23.37 0 011.77 14.73zM59.55 19.94L58 16.58l-9.85-4.71a3.09 3.09 0 01-1.76-3.29 2.06 2.06 0 011.87-1.74s-1.16-4.55 2.22-4.32a3.4 3.4 0 012-4.43"
        stroke="#b56545"
      />
      <path
        d="M64.71 17.51l-1.58-3.36 2.66-10.59A3.11 3.11 0 0064.37.11a2.05 2.05 0 00-2.53.33s-2.77-3.8-4.75-1a3.41 3.41 0 00-4.67-1.3m28.06 41.93l-3.79 1.79m-11.33 3.01l2.84-1.34m4.75-21.93l-4.74 2.23"
        stroke="#b56545"
      />
    </g>
    <rect
      x={1.5}
      y={1.5}
      width={135}
      height={135}
      rx={5.8}
      fill="none"
      stroke="#824028"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
    />
  </svg>
);
