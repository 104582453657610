import React from "react";
import styled from "styled-components";
import { theme } from "../../../ui/theme";
import { Wrapper } from "../components";
import { GiantButton } from "../SubscriptionInfo/components";
import { HEADER_HEIGHT } from "./components";
import { whiteSvgBackground } from "./whiteSvgBackground";
import { changeUrl } from "../../../utils/changeUrl";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const Container = styled.div({
  display: "flex",
  paddingTop: 60 + HEADER_HEIGHT,
  paddingBottom: 60,
  paddingLeft: 8,
  paddingRight: 8,
  "@media only screen and (max-width: 900px)": {
    paddingTop: 30 + HEADER_HEIGHT,
    alignItems: "center",
    flexDirection: "column-reverse",
  },
});

const H1 = styled.div({
  marginTop: 0,
  fontFamily: theme.fonts.secondary,
  fontWeight: 900,
  width: "100%",
  maxWidth: 330,
  fontSize: "2.625rem",
  lineHeight: "2.8125rem",
  color: "#444444",
  "@media only screen and (max-width: 900px)": {
    maxWidth: "100%",
    textAlign: "center",
  },
});

const Description = styled.div({
  fontSize: "1.125rem",
  width: "100%",
  maxWidth: 320,
  color: "#666",
  lineHeight: "2rem",
  marginTop: 16,
  "@media only screen and (max-width: 900px)": {
    maxWidth: "100%",
    textAlign: "center",
  },
});

const ButtonContainer = styled.div({
  width: "100%",
  display: "flex",
  marginTop: 40,
  maxWidth: 300,
  "@media only screen and (max-width: 900px)": {
    maxWidth: 260,
    margin: "auto",
    marginTop: 30,
  },
});

export const Hero = () => {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      file(relativePath: { eq: "hero-img.png" }) {
        childImageSharp {
          fluid(maxWidth: 608) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div
      style={{
        backgroundColor: "#EFF7F1",
        backgroundImage: whiteSvgBackground,
      }}
    >
      <Wrapper>
        <Container>
          <div style={{ width: "100%", maxWidth: 360 }}>
            <H1>The home for all your recipes</H1>
            <Description>
              Save your favorite recipes in one place. Import just the recipe
              from any website without the distractions or clutter. Create meal
              plans, and generate grocery lists—Saffron’s everything a cook
              needs.
            </Description>
            <ButtonContainer>
              <GiantButton
                style={{
                  width: "100%",
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: "1.125rem",
                  maxWidth: 240,
                }}
                onClick={() => changeUrl("/register")}
              >
                Create an Account
              </GiantButton>
            </ButtonContainer>
          </div>
          <div style={{ flex: 1, width: "100%" }}>
            <Img
              alt="cooking app recipe view"
              fluid={data.file.childImageSharp.fluid}
            />
          </div>
        </Container>
      </Wrapper>
    </div>
  );
};
