import React from "react";
import styled from "styled-components";
import { Wrapper } from "../components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

interface Props {}

const Container = styled.div({
  display: "flex",
  alignItems: "center",
  "& img": {
    paddingRight: 50,
    height: 490,
  },
  "& .right-col": {
    paddingTop: 65,
    paddingBottom: 65,
  },
  "@media only screen and (max-width: 590px)": {
    flexDirection: "column",
    "& img": {
      paddingRight: 0,
      height: 200,
      width: "100%",
    },
    "& .right-col": {
      paddingTop: 20,
      paddingBottom: 50,
      paddingLeft: ".5em",
      paddingRight: ".5em",
    },
  },
});

const ParaTitle = styled.div({
  fontWeight: 800,
  fontSize: "1.25rem",
  color: "#333333",
  marginBottom: 4,
});

export const TextBlock: React.FC<Props> = () => {
  const data = useStaticQuery(graphql`
    query OliveOil {
      file(relativePath: { eq: "olive-oil.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 378, fit: COVER, maxHeight: 490, cropFocus: ENTROPY) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div style={{ backgroundColor: "#EAE9E1" }}>
      <Wrapper>
        <Container>
          <div style={{ flex: 6, display: "flex" }}>
            <Img
              style={{
                objectFit: "cover",
                flex: 6,
              }}
              fluid={data.file.childImageSharp.fluid}
              alt="olive oil"
            />
          </div>
          <div
            className="right-col"
            style={{
              margin: "auto",
              flex: 7,
            }}
          >
            <div
              className="right-paragraph"
              style={{
                fontSize: "1.125rem",
                lineHeight: "1.625rem",
                color: "#666666",
              }}
            >
              <ParaTitle>Make recipes your own</ParaTitle>
              <div style={{ marginBottom: 30 }}>
                With Saffron’s recipe editor, you can easily edit recipes, save
                adjustments to ingredients, and add additional steps or tips to
                your preparation.
              </div>
              <ParaTitle>All in one place</ParaTitle>
              <div style={{ marginBottom: 30 }}>
                Storing your recipes in Saffron allows you to quickly search,
                find, and select what you want to cook.
              </div>
              <ParaTitle>Cook from your favorite device</ParaTitle>
              <div>
                Saffron stores your recipes in the Cloud so you can access them
                on any device through our website or Android/iOS app.
              </div>
            </div>
          </div>
        </Container>
      </Wrapper>
    </div>
  );
};
