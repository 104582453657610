import styled from "styled-components";
import { baseButton } from "../../../ui/Button";
import { theme } from "../../../ui/theme";

export const GiantButton = styled("button")`
  ${baseButton};
  font-family: ${theme.fonts.secondary};
  color: #fff;
  background-color: #531410;
  font-size: 1.5rem;
  padding: 20px 2em;
  width: 100%;
  border: solid 1px #531410;
  max-width: 500px;
  &:hover {
    background-color: #fff;
    color: #531410;
    border: solid 1px #531410;
  }
`;
