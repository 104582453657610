import React, { useRef } from "react";
import { theme } from "../../ui/theme";
import { imgBoxShadow } from "./components";
import styled from "styled-components";

interface Props {
  headerText: string;
  descriptionText: string;
  src: string;
  alt: string;
  fix?: boolean;
}

const Container = styled.div({
  display: "flex",
  backgroundColor: "#F3F2F0",
  padding: "16px 20px",
  marginBottom: 20,
  alignItems: "flex-end",
  marginTop: 140,
  "@media only screen and (max-width: 750px)": {
    marginTop: 70,
    alignItems: "flex-start",
    flexDirection: "column",
    textAlign: "center",
    "& > h3": {
      width: "100%",
      marginRight: "0px !important;",
    },
  },
});

export const GifBlock: React.FC<Props> = ({
  headerText,
  descriptionText,
  src,
  alt,
  fix,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  return (
    <>
      <Container style={fix ? { marginTop: 0 } : undefined}>
        <h3
          style={{
            color: "#637E93",
            fontFamily: theme.fonts.secondary,
            margin: "auto",
            fontWeight: 300,
            fontSize: "2.5rem",
            lineHeight: "2.5625rem",
            marginRight: 16,
          }}
        >
          {headerText}
        </h3>
        <div
          style={{
            marginBottom: 1,
            marginTop: 6,
            fontSize: "1.125rem",
            color: "#666",
            lineHeight: "1.5rem",
            flex: 1,
          }}
        >
          {descriptionText}
        </div>
      </Container>
      <video
        ref={videoRef}
        autoPlay
        loop
        muted
        playsInline
        title={alt}
        style={{
          objectPosition: fix ? "0px -1px" : undefined,
          borderRadius: 5,
          boxShadow: imgBoxShadow,
          maxWidth: "100%",
        }}
        onClick={() => {
          if (!videoRef.current) {
            return;
          }

          if (videoRef.current.paused == true) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        }}
      >
        <source src={src} type="video/mp4"></source>
        <p>
          Your browser doesn't support HTML5 video. Here is a{" "}
          <a href={src}>link to the video</a> instead.
        </p>
      </video>
    </>
  );
};
